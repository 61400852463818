/* base styles */
* {
    margin: 0;
    font-family: "Roboto";
}

.projects {
    color:darkblue;
    padding-bottom: 50px;
}
.projects .title {
    text-align: center;
    padding-top: 50px;
    font-size: 30px;
    padding-bottom: 10px;
}
.projects .titledescription {
    text-align: center;
    padding-bottom: 35px;
}
.flexbox {
    display: flex;
    padding-bottom: 52px;
    justify-content: center;
    width: 100%;
    color: #041446;
}
.flexboxtwo {
    display: flex;
    padding-bottom: 20px;
    justify-content: center;
    width: 100%;
    color: #041446;
}
.projectlist {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(3, 1fr);
    /* grid-template-columns: 1fr 2fr; */
    grid-column-gap: 52px;
    grid-row-gap: 52px;
    /* color: #041446; */
}
.projectlisttwo {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 52px;
    grid-row-gap: 52px;
}
@media (min-width: 0px) { 
    .projectlist {
        grid-template-columns: repeat(1, 1fr);
    }
    .projectlisttwo {
        grid-template-columns: repeat(1, 1fr);
    }
}
@media (min-width: 768px) { 
    .projectlist {
        grid-template-columns: repeat(2, 1fr);
    }
}
@media (min-width: 1172px) { 
    .projectlist {
        grid-template-columns: repeat(3, 1fr);
    }
    .projectlisttwo {
        grid-template-columns: repeat(2, 1fr);
    }
}
.projectbox {
    display: inline-block;
    /* width: 300px;
    height: 400px; */
    width: 300px;
    height: 400px;
    padding: 0 25px;
    padding-top: 10px;
    padding-bottom: 30px;
    overflow: hidden;
    /* background-color: #041446; */
    background-color: #F3F3F3;
    position: relative;
}
.projectbox.combined {
    width: 501px;
    height: 400px;
    /* grid-row-start: 2;
    grid-column-start: 1;
    grid-column-end: 2; */
}
/* .projectflexbox {
    display: flex;
} */
.projecttitle {
    text-align: center;
    font-weight: bold;
    font-size: 30px;
    padding-bottom: 10px;
}
.projects img {
    padding-bottom: 15px;
    max-height: 120px;
}
/* .projects .projectimg {

} */
.projectdescription {
    text-align: start;
}
.pillflexbox {
    display: flex;
    gap: 20px;
    padding-top: 15px;
    padding-right: 5px;
    padding-bottom: 3px;
    position: absolute;
    bottom: 10px;
    padding-bottom: 10px;
}
.pill {
    text-decoration: none;
    display: inline-block;
    padding: 8px 15px;
    background-color: #041446; 
    color: #F3F3F3; 
    border-radius: 25px; 
    text-align: center;
    font-size: 11px;
    cursor: pointer; 
}
.pill:hover {
    color: white;
    background-color: #3365FF;
}

.hackathonflexbox {
    display: flex;
    justify-content: center;
    width: 100%;
}
.hackathontitle {
    text-align: center;
    font-size: 20px;
    padding-top: 20px;
    /* margin-top: -50px; */
}
.hackathonname {
    text-align: center;
    padding-bottom: 10px;
    padding-top: 30px;
    font-size: 21px;
    color: #041446;
}
.hackathonimage {
    max-width: 280px;
    max-height: 280px;
    scale: 1.07;
}
.hackathon:hover .hackathonimage img {
    opacity: 0.5;
}
.hover-options {
    position: absolute;
    top: 30%;
    bottom: 30%;
    width: 100%;
    color: white;
    display: flex;
    justify-content: space-around;
    align-items: center;
    opacity: 0;
    height: 50px;
    transition: opacity 0.3s;
    z-index: 1;
}
.hackathonimage:hover .hover-options {
    opacity: 1;
}
.hackathonlist {
    display: grid;
    align-items: flex-start;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 52px;
    color: darkblue;
}
/* .timeline {
    color: darkblue;
}

.title {
    text-align: center;
    padding-bottom: 60px;
    font-size: 25px;
    color: darkblue;
}

.intro {
    display: flex;
    justify-content: space-around;
    padding-right: 275px;
    padding-left: 275px;
    padding-bottom: 70px;
    color: darkblue;
}

.line {
    width: 20%;
    border-top: 2px solid darkblue;
}

.top {
    padding-bottom: 60px;
}

.date {
    margin-left: 500px;
}

.name {
    margin-left: 175px;
    font-size: 60px;
    font-weight: bold;
    text-align: center;
}

.info {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
}

.description {
    max-width: 400px;
    margin-left: 100px;
}

.timeline img {
    max-width: 400px;
    max-height: 400px;
    margin-left: 650px;
} */

