.workexperience {
    color: darkblue;
    background-color: #F3F3F3;
    padding-bottom: 30px;
}
.workexperience .worklist {
    display: grid;
    padding-left: 20px;
    padding-bottom: 40px;
    place-items: center;
}
.workexperience .title {
    padding-top: 40px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 30px;
}
.workexperience .workbox {
    display: inline-block;
    position: relative;
    padding: 10px 20px;
    margin: 10px 50px;
    width: 710px;
    height: auto;
    padding-bottom: 5px;
    font-size: 15px;
}
.workexperience .workbox::before {
    content: '{';
    font-size: 170px;
    font-weight: 70;
    position: absolute;
    left: -50px;
    top: 50%;
    transform: translateY(-50%);
}
.workexperience .workbox::after {
    content: '}';
    font-size: 170px;
    font-weight: 70;
    position: absolute;
    right: 1px;
    top: 50%;
    transform: translateY(-50%);
}
.workexperience .work {
    padding-bottom: 10px;
}
.workexperience .workdescription {
    border-left: 1px solid darkblue;
    padding: 0 15px;
    max-width: 700px;
}
.workexperience p {
    padding-bottom: 10px;
}
.workexperience h2 {
    padding-bottom: 10px;
}
.workexperience .workname {
    padding-bottom: 10px;
}