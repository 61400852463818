@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;600;700&display=swap');

/* base styles */
* {
  margin: 0;
  font-family: "Roboto";
}
.navbar {
  padding-top: 20px;
  padding-bottom: 20px;
  /* padding-right: 50px;
  padding-left: 50px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
  color: black;
}
.navbar h1 {
  font-size: 40px;
  margin-left: 40px;
}
.center {
  text-align: center;
}
.right {
  scale: 1.4;
  margin-right: 50px;
}
.navbar a {
  padding: 9px;
  color: darkblue;
  text-decoration: none;
  font-weight: bold;
  font-size: 18px;
}
.navbar a:hover {
  color: #3365FF;
} 
.content {
  padding-top: 100px;
} 