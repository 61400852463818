.contact {
    color: #041446;
    background-color: white;
    padding-top: 60px;
    padding-bottom: 60px;
}
.contactbox {
    display: flex;
    justify-content: center;
    gap: 140px;
}
.ping {
    padding-bottom: 20px;
    font-size: 30px;
}
.contactinfo {
    padding-bottom: 20px;
}
.contact p {
    padding-bottom: 10px;
}
.contact .form {
    display: grid;
    gap: 10px;
    width: 700px;
}
form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
}
label:nth-child(1), label:nth-child(2) {
    grid-column: span 1;
    padding-bottom: 15px;
}
label:nth-child(3) {
    grid-column: span 2;
    padding-bottom: 15px;
}
label:nth-child(4) {
    grid-column: span 2;
    padding-bottom: 15px;
}

input[type="text"],
input[type="email"],
textarea {
    width: 100%;
    height: 45px;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background-color: #FCFCFC;
}
textarea {
    resize: vertical;
    height: 100px;
}
input[type="submit"] {
    width: 100%;
    grid-column: span 2;
    cursor: pointer;
}
