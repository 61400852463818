.experience {
    color:darkblue;
    background-color: #F3F3F3;
    padding-bottom: 40px;
}
.flexbox {
    display: flex;
    align-items: center;
}
/* .experience .vertical-line {
    width: 1px;
    height: 50%;
    background-color: darkblue;
    margin-right: 10px;
} */
.experience .placelist {
    /* margin-left: 20%; */
    /* max-width: 60%; */
    display: grid;
    /* border-left: 1px solid darkblue; */
    padding-left: 20px;
    padding-bottom: 40px;
    place-items: center;
}
.experience .title {
    padding-top: 40px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 30px;
}
.experience .placebox {
    display: inline-block;
    position: relative;
    padding: 10px 20px;
    margin: 10px 50px;
    width: 550px;
    height: auto;
    padding-bottom: 5px;
    font-size: 15px;
}
.experience .placebox::before {
    content: '{';
    font-size: 140px;
    font-weight: 100;
    position: absolute;
    left: -50px;
    top: 50%;
    transform: translateY(-50%);
}
.experience .placebox::after {
    content: '}';
    font-size: 140px;
    font-weight: 100;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
}
.experience .school {
    padding-bottom: 10px;
}
.experience .schooldescription {
    border-left: 1px solid darkblue;
    padding: 0 15px;
}
.experience p {
    padding-bottom: 10px;
}