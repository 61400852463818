/* base styles */
*{
    margin: 0;
    font-family: "Roboto";
}

.Introduction {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    padding-bottom: 180px;
}

.profilepic {
    width: 500px;
    height: 500px;
    border-radius: 50%;
    overflow: hidden;
    border: 2px solid white;
}
img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.Introduction .about {
    color: darkblue;
    margin-left: 300px;
    max-width: 600px;
}
.Introduction .name {
    font-size: 70px;
    font-weight: bold;
    padding-bottom: 10px;
}
.Introduction .description {
    font-size: 30px;
    padding-bottom: 20px;
}
.Introduction .introbarflex {
    display: flex;
}

.Introduction .introcomponents {
    display: inline-block;
    padding: 15px 25px;
    background-color: #041446;
    color: #f3f3f3;
    text-align: center;
    font-size: 18px;
    cursor: pointer;
}
.Introduction a {
    text-decoration: none;
    color: #f3f3f3;
}
.Introduction a:visited {
    color: inherit;
}
.Introduction .introcomponents:hover {
    color: white;
    background-color: #3365FF;
}